/*
*	Ober - Personal Portfolio Template (HTML)
*	Version: 1.0
*	Author: bslthemes
*	Author URL: http://themeforest.net/user/bslthemes
*	Copyright © Ober by bslthemes. All Rights Reserved.
*/

/* TABLE OF CONTENTS
	- Header
	- Section
	- Started
	- About
	- Resume
	- Skills
	- Service
	- Portfolio
	- Box Items
	- Contacts
	- Blog
	- Footer
	- Popups
*/
